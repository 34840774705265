/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 9/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import ValidatedTextInput from "../../common/ValidatedTextInput";
import ValidatedPhoneNumberInput from "../../common/ValidatedPhoneNumberInput";
import {updateCampableUser} from "../../../api/api";
import {campableUserReceived, showAlert} from "../../../actions/index";
import Dialog from "../../common/widgets/Dialog";

class AccountDetailsDialog extends Component {


    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false
        };
    }

    onSubmit(values) {
        this.setState({
            loading: true,
        });

        if (!this.props.pristine || this.props.dirty) {
            let user = {
                names: {
                    firstName: values.firstName,
                    lastName: values.lastName
                },
                contact: {
                    phone: values.phone,
                }
            };

            updateCampableUser(user).then((userResponse) => {
                this.props.dispatch(campableUserReceived(userResponse));
                this.props.dispatch(showAlert('success', 'Account details successfully updated', ''));
                this.setState({
                    loading: false,
                });
                this.accountDetailsDialog.dismiss();
                this.props.onBookNowPress();
            }).catch((err) => {
                this.props.dispatch(showAlert('danger', 'There was an error while updating your account details.', 'If this problem persists please contact Campable support. Error: ' + err));
                this.setState({
                    loading: false,
                });
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    render() {

        return (
            <Dialog
                ref={(ref) => {
                    this.props.setRef(ref);
                    this.accountDetailsDialog = ref;
                }}
                loading={this.state.loading}
                title={'Account Details'}
                body={'Before you are able to book, you need to configure your account details.'}
                positiveText={'Update Account'}
                negativeText={'Cancel'}
                dontDismissOnConfirmPress
                onPositivePress={this.props.handleSubmit(this.onSubmit)}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <h5 className="required-text">Fields marked with <span className="star">*</span> are required</h5>
                    <br/>
                    <br/>
                    <ValidatedTextInput label="First Name" placeholder="e.g. John" name="firstName" required/>
                    <ValidatedTextInput label="Last Name" placeholder="e.g. Smith" name="lastName" required/>
                    <ValidatedPhoneNumberInput label="Mobile Phone (for SMS notifications)" placeholder="e.g. +64271234567" name="phone" required/>
                </form>
            </Dialog>
        );
    }
}

AccountDetailsDialog = reduxForm({
    form: 'accountDetailsForm',
    validate: (values, props) => {
        let errors = {};

        if (!values.lastName || values.firstName === "") {
            errors.firstName = 'Required';
        }

        if (!values.firstName || values.lastName === "") {
            errors.lastName = 'Required';
        }

        if (!values.phone) {
            errors.phone = 'Required';
        } else if (values.phone.length < 9) {
            errors.phone = 'Invalid Phone Number';
        }

        return errors;
    }
})(AccountDetailsDialog);

export default AccountDetailsDialog = connect((state) => {
    return {
        initialValues: {
            firstName: state.data.campableUser ? state.data.campableUser.firstName : '',
            lastName: state.data.campableUser ? state.data.campableUser.lastName : '',
            phone: state.data.campableUser ? state.data.campableUser.phone : ''
        }
    }
})(AccountDetailsDialog);


