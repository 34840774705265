import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../App.css';
import Load from '../../components/Load';
import {Col, Grid, Row} from 'react-bootstrap';
import {adminFetchTransactions, getAccountBalance} from "../../actions/index";
import HostTransactionsController from '../../components/hostTransactions/HostTransactionsController';

class AdminTransactionsContainer extends Component {

    constructor(props) {
        super(props);

        this.onNextPageClick = this.onNextPageClick.bind(this);

        this.state = {
            accountBalance: null,
        };
    }


    componentDidMount() {
        if (this.props.user) {
            this.props.dispatch(adminFetchTransactions(this.props.user.id));
            this.props.dispatch(getAccountBalance(this.props.user.id, this.onBalanceSuccess));
        }
    }

    componentWillReceiveProps(nextProps) {
        const user = nextProps.user;
        if (!!user && !this.props.user) {
            nextProps.dispatch(adminFetchTransactions(user.id));
            nextProps.dispatch(getAccountBalance(user.id, this.onBalanceSuccess));
        }
    }

    onBalanceSuccess = (accountBalance) => {
        console.log("accountBalanceaccountBalance", accountBalance)

        this.setState({
            accountBalance: accountBalance,
        });
    };

    onNextPageClick(cursor) {
        this.props.dispatch(adminFetchTransactions(this.props.user.id, cursor));
    }

    /*componentWillUnmount() {
        this.props.dispatch(adminFetchTransactions())
    }*/

    render() {

        let renderScene = (
            <div className="loaderContainer">
                <Load/>
            </div>
        );

        const transactions = {
            items: this.props.transactions,
            cursor: this.props.transactionsCursor,
        };


        if (!!transactions) {
            renderScene = (
                <HostTransactionsController
                    accountBalance={this.state.accountBalance}
                    transactions={transactions}
                    onNextPageClick={this.onNextPageClick}
                />);
        }


        return (
            <Grid className="host-transactions-container" style={{width: '100%'}}>
                <Row>
                    <h1>Transactions</h1>
                    <Col md={12}>
                        {renderScene}
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default AdminTransactionsContainer = connect((state, props) => {

    const userTransactions = state.admindata.userTransactions && state.admindata.userTransactions[props.user.id] ? state.admindata.userTransactions[props.user.id] : '';

    return {
        transactions: userTransactions,
        transactionsCursor: state.userTransactionsCursor
    }
})(AdminTransactionsContainer);