import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Pager} from "react-bootstrap";
import ValidatedTextInput from "../common/ValidatedTextInput";
import {PhoneNumberUtil} from 'google-libphonenumber';
import Card from "../Card";
import ValidatedPhoneNumberInput from "../common/ValidatedPhoneNumberInput";
import Loading from "react-loading";
import {updateCampableUser} from "../../api/api";
import {campableUserReceived, showAlert} from "../../actions/index";

class AccountDetailsController extends Component {


    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false
        };
    }

    onSubmit(values) {
        this.setState({
            loading: true,
        });

        if (!this.props.pristine || this.props.dirty) {
            let user = {
                names: {
                    firstName: values.firstName,
                    lastName: values.lastName
                },
                contact: {
                    phone: values.phone,
                }
            };

            user.id = this.props.campableUser.id;
            updateCampableUser(user).then((userResponse) => {
                this.props.dispatch(campableUserReceived(userResponse));
                this.props.dispatch(showAlert('success', 'Account details successfully updated', ''));
                this.setState({
                    loading: false,
                });
                this.props.onNextStepPress();
            }).catch((err) => {
                this.props.dispatch(showAlert('danger', 'There was an error while updating your account details.', 'If this problem persists please contact Campable support. Error: ' + err));
                this.setState({
                    loading: false,
                });
            });
        } else {
            this.setState({
                loading: false,
            });
            this.props.onNextStepPress();
        }
    }

    render() {

        let backButton = null;
        if (!!this.props.onBackPress) {
            backButton = (
                <Pager.Item previous onClick={this.props.onBackPress}>Back</Pager.Item>
            );
        }

        let required = null;

        if (this.props.isHost) {
            required = <h5 className="required-text">Fields marked with <span className="star">*</span> are required</h5>;
        }

        let loading = null;
        if (this.state.loading) {
            loading = (
                <div className="onboarding-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'
                    />
                </div>
            );
        }

        return (
            <Card title="Account details" className="account-details-container">
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    {required}
                    <ValidatedTextInput label="First Name" placeholder="e.g. John" name="firstName" required={!!required}/>
                    <ValidatedTextInput label="Last Name" placeholder="e.g. Smith" name="lastName" required={!!required}/>
                    <ValidatedPhoneNumberInput label="Mobile Phone (for SMS notifications)" placeholder="e.g. +64271234567" name="phone" required={!!required}/>
                    <Pager>
                        {backButton}
                        <Pager.Item next type="submit" disabled={!this.props.valid && !!required} onClick={this.props.handleSubmit(this.onSubmit)}>Next</Pager.Item>
                    </Pager>
                </form>
                {loading}
            </Card>
        );
    }
}

AccountDetailsController = reduxForm({
    form: 'accountDetailsForm',
    validate: (values, props) => {


        let errors = {};

        if (props.isHost) {
            if (!values.lastName || values.firstName === "") {
                errors.firstName = 'Required';
            }

            if (!values.firstName || values.lastName === "") {
                errors.lastName = 'Required';
            }

            if (!values.phone) {
                errors.phone = 'Required';
            }
            else{
                try {
                    const phoneUtil = PhoneNumberUtil.getInstance();
                    const valid = phoneUtil.isValidNumber(phoneUtil.parse(values.phone));
                    if(!valid){
                        errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789'
                    }
                }
                catch(e) {
                    errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789';
                }
            }
        } else {

            //TODO When user i inputting their first name or last name make sure the other is also valid. So you have to have both to continue.

            if (!values.phone) {
                errors.phone = 'Required';
            }
            else{
                try {
                    const phoneUtil = PhoneNumberUtil.getInstance();
                    const valid = phoneUtil.isValidNumber(phoneUtil.parse(values.phone));
                    if(!valid){
                        errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789'
                    }
                }
                catch(e) {
                    errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789';
                }
            }
        }

        return errors;
    }
})(AccountDetailsController);

export default AccountDetailsController = connect((state) => {
    return {
        initialValues: {
            firstName: state.data.campableUser.firstName,
            lastName: state.data.campableUser.lastName,
            phone: state.data.campableUser.phone
        },
        campableUser: state.data.campableUser
    }
})(AccountDetailsController);

