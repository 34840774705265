import React, {Component} from "react";
import {ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import "../../../containers/inline-styles/phone-number-input.css";

export default class PhoneNumberInput extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }


    onChange(event, x) {
        this.props.input.onChange(event, x);
    }

    render() {
        let validationState = null;
        let helpBlock;

        if (this.props.meta && this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>;
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;

        return (
            <FormGroup readOnly validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <FormControl
                    {...this.props.input}
                    {...inputProps}/>
                {helpBlock}
            </FormGroup>)
    }
}




